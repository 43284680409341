import { getRoot, types } from "mobx-state-tree";
import { validate } from "../helpers";
import {normalizeText} from "../helpers/stringTools";


const PersonFormField = types.model("PersonFormField", {
  value: "",
  error: "",
  dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
});

const PersonStore = types
  .model("PersonStore", {
    firstName: types.optional(PersonFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    lastName: types.optional(PersonFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    phoneNumber: types.optional(PersonFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    email: types.optional(PersonFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    emailConfirm: types.optional(PersonFormField, {
      value: "",
      error: "",
      dirty: false
    })
  })
  .actions(self => {
    return {
      loadPersonState(state) {
        for (let key of state) {
          self[key] = state[key];
        }
      },

      onBlurPersonFieldValidation(fieldName) {
        const fieldValue = self[fieldName].value;
        switch (fieldName) {
          case "firstName":
            if (fieldValue.length <= 2) {
              self.setPersonFieldError(
                fieldName,
                "First name must be at least three characters long."
              );
            }
            else if (fieldValue.trim().includes(" ")) {
              self.setPersonFieldError(
                fieldName,
                "First name must be a single word (cannot combine 2 names)"
              );
            }
            else {
              self.setPersonFieldError(fieldName, "");
            }
            break;
          case "lastName":
            if (fieldValue.length <= 2) {
              self.setPersonFieldError(
                fieldName,
                "Last name must be at least three characters long."
              );
            }
            else if (fieldValue.trim().includes(" ")) {
              self.setPersonFieldError(
                fieldName,
                "Last name must be a single word (cannot combine 2 names)"
              );
            }
            else {
              self.setPersonFieldError(fieldName, "");
            }
            break;
          default:
            break;
        }

        const { payment } = getRoot(self);
        return payment.checkIfCanSubmit();
      },

      onChangePersonFieldValidation(fieldName, fieldValue) {
        const field = self[fieldName];
        const { payment } = getRoot(self);

        switch (fieldName) {
          case "firstName":
            if (field.dirty) {
              if (fieldValue.length <= 2) {
                self.setPersonFieldError(
                  fieldName,
                  "First name must be at least two characters long."
                );
              } else if (validate.isNumeric(fieldValue)) {
                self.setPersonFieldError(
                  fieldName,
                  "First name can not be only numbers."
                );
              } else {
                self.setPersonFieldError(fieldName, "");
              }
            }
            break;
          case "lastName":
            if (field.dirty) {
              if (fieldValue.length <= 2) {
                self.setPersonFieldError(
                  fieldName,
                  "Last name must be at least two characters long."
                );
              } else if (validate.isNumeric(fieldValue)) {
                self.setPersonFieldError(
                  fieldName,
                  "Last name can not be only numbers."
                );
              } else {
                self.setPersonFieldError(fieldName, "");
              }
            }
            break;
          case "phoneNumber":
            {
              const isValidPhoneNumber = validate.phoneNumber(fieldValue);
              if (!isValidPhoneNumber) {
                self.setPersonFieldError(
                  fieldName,
                  "Not a valid phone number."
                );
              } else {
                self.setPersonFieldError(fieldName, "");
              }
            }
            break;
          case "email":
            {
              const isValidEmail = validate.email(fieldValue);
              if (!isValidEmail) {
                self.setPersonFieldError(fieldName, "Not a valid email.");
                self.setPersonFieldDisabled("emailConfirm", true);
              } else {
                self.setPersonFieldError(fieldName, "");
                self.setPersonFieldDisabled("emailConfirm", false);
                // lets compare to the emailConfirm field
                const emailConfirm = self.emailConfirm.value;
                self.onChangePersonFieldValidation(
                  "emailConfirm",
                  emailConfirm
                );
              }
            }
            break;
          default:
            break;
        }
        return payment.checkIfCanSubmit();
      },

      setPersonField(fieldName, fieldValue) {
        self.setPersonFieldValue(fieldName, fieldValue);
        self.onChangePersonFieldValidation(fieldName, fieldValue);
        const { payment } = getRoot(self);
        return payment.checkIfCanSubmit(payment);
      },

      setPersonFieldValue(fieldName, fieldValue) {
        // Apply normalization for firstName and lastName
        if (fieldName === "firstName" || fieldName === "lastName") {
          fieldValue = normalizeText(fieldValue);
        }
        self[fieldName].value = fieldValue;
      },
      setPersonFieldDirty(fieldName) {
        self[fieldName].dirty = true;
      },
      setPersonFieldError(fieldName, fieldError) {
        self[fieldName].error = fieldError;
      },
      setPersonFieldDisabled(fieldName, disabled) {
        self[fieldName].disabled = disabled;
      }
    };
  });

export default PersonStore;
