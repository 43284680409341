import React, {Fragment, useEffect, useRef, useState} from "react"
import useStore from "../hooks/useStore"
import { observer } from "mobx-react"
import { Container, Row, Col, Form, Fade } from "reactstrap"
import { Frames } from "frames-react"
import { objectToArray, STEPS, phoneNumbers } from "../helpers"
import {
  Button,
  StatusModal,
  FullInput,
  BraintreeDropIn,
  CheckoutSummary,
  CheckBox,
  IDSelector
} from "../components"
import CkoDropin from "../components/payment/dropins/CkoDropin"
import EvervaultDropin from "../components/payment/dropins/EvervaultDropin"
import EVProcessingModal from "../components/evProcessingModal"

const PaymentComponent = props => {
  const {
    violation: violations,
    search,
    payment,
    person,
    general
  } = useStore()

  const [BraintreeInstance, setBraintreeInstance] = useState(null)
  const evervaultCardRef = useRef(null)
  useEffect(() => {
    if (process.env.REACT_APP_USE_BRAINTREE === "true") {
      if (!payment.clientToken) {
        payment.requestClientToken()
      }
    }

    if (process.env.REACT_APP_USE_BRAINTREE === "true") {
      console.info("Braintree TRUE")
    }
  }, [payment])

  const setInstance = instance => {
    if (process.env.REACT_APP_USE_BRAINTREE === "true") {
      setBraintreeInstance(instance)
    }
  }

  const handleChange = event => {
    const target = event.target.name
    const { value } = event.target
    let safeValue = value
    if (target === "phoneNumber") {
      safeValue = phoneNumbers.cleanPhoneNumberInput(safeValue)
    }
    person.setPersonField(target, safeValue)
  }

  const handleBlur = event => {
    const { name } = event.target;
    person.setPersonFieldDirty(name)
    person.onBlurPersonFieldValidation(name)
  }

  const handleImageSelection = (event, key) => {
    payment.uploadImage(event, key)
    payment.checkIfCanSubmit()
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (payment.enableSubmit) {
      payment.setSubmitted(true)
      if (process.env.REACT_APP_USE_BRAINTREE === "true") {
        BraintreeInstance.requestPaymentMethod()
          .then(payment.submitOrder)
          .catch(err => {
            console.error("error generating nonce", err) // eslint-disable-line
            return payment.setSubmitted(false)
          })
      } else if (process.env.REACT_APP_USE_EVERVAULT === 'false') {
        try {
          await Frames.submitCard()
        } catch (error) {
          payment.setSubmitted(false)
          console.error(error)
        }
      }
      else { // EV
        const cardResult = await evervaultCardRef.current.validate()
        if (!cardResult?.values?.isValid || !cardResult.values.isComplete) {
          payment.setErrorToast("Please fill out all the fields")
          payment.setSubmitted(false)
          return
        }
        payment.setErrorToast('')

        const cardValues = evervaultCardRef.current.values?.card
        const currentYearLastTwoDigits = new Date().getFullYear().toString().slice(-2)
        const maxValidYear = parseInt(currentYearLastTwoDigits, 10) + 10 // Allow up to 10 years from now since CityPay doesn't allow more than 10 years
        if (cardValues?.expiry && cardValues?.expiry.year) {
          const cardYear = parseInt(cardValues.expiry.year, 10)

          if (cardYear > maxValidYear) {
            payment.setErrorToast( "Invalid Expiration Year: Must be less than 10 years from now." )
            payment.setSubmitted(false)
            return
          }
        }

        if (cardValues?.brand === 'jcb' || cardValues?.brand === 'diners-club') {
          payment.setErrorToast(
            `${cardValues?.brand.toUpperCase()} cards are not accepted, please use a different card brand`
          )
          payment.setSubmitted(false)
          return
        }
        payment.setIsChicagoBotRunning(true)
        const cardExp = `${cardValues?.expiry?.month}/${cardValues?.expiry?.year}`
        const payData ={
          cardNumberAlias: cardValues?.number,
          cardExp,
          cardCvcAlias:  cardValues?.cvc,
          cardBrand: cardValues?.brand,
        }
        try {
          const chargeAuthResponse = await payment.preAuthorizeChargeWithEv(payData)
          if (chargeAuthResponse?.data.error) {
            payment.setErrorToast(
              `${cardValues?.brand.toUpperCase()} Card Authorization error: ${chargeAuthResponse?.data.error}`
            )
            payment.setSubmitted(false)
            payment.setIsChicagoBotRunning(false)
            return
          }
          const payDataBot = {
            paymentId: chargeAuthResponse.data.cityPaySessionId,
            cardNumberAlias: cardValues?.number,
            cardExp,
            cardCvcAlias: cardValues?.cvc
          }
          await payment.payCityBot(payDataBot)
        } catch (error) {
          console.error('Error in submitting payment', error)
          payment.setSubmitted(false)
          payment.setIsChicagoBotRunning(false)
        }
      }
    }
  }

  const selectedViolations = objectToArray(violations.selected)
  const plateNumber = search.plateNumber || selectedViolations[0].licensePlate

  const numTickSel = selectedViolations.length
  const { totalSelectedFines } = violations
  let braintreeDropInOptions
  if (process.env.REACT_APP_USE_BRAINTREE === "true") {
    braintreeDropInOptions = {
      authorization: payment.clientToken,
      card: {
        cardholderName: {
          required: true
        }
      }
    }
    if (process.env.REACT_APP_BT_ANTI_FRAUD === "true") {
      braintreeDropInOptions.dataCollector = {
        kount: true // Required if Kount fraud data collection is enabled
        // paypal: true // Required if PayPal fraud data collection is enabled
      }
    }
    if (process.env.REACT_APP_THREED_ENABLED === "true") {
      braintreeDropInOptions.threeDSecure = {
        amount: violations.orderTotal.toFixed(2)
      }
    }
  }
  return (
    <Fragment>
      {search.fetching && <StatusModal message="Uploading Image..." />}
      {payment.submitted && process.env.REACT_APP_USE_EVERVAULT === 'false' ? (
        <StatusModal message="Submitting Your Order..." />
      ) : null}
      {payment.isChicagoBotRunning && (
        <EVProcessingModal isChicagoBotRunning={payment.isChicagoBotRunning} />
      )}
      <Col>
        <Row>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <CheckoutSummary
                  editViolations={() => {
                    general.setStep(STEPS.result)
                  }}
                  text={
                    process.env.REACT_APP_USE_BRAINTREE === "true"
                      ? "Your payment details are secured by Braintree."
                      : "Your payment details are secured by Checkout.com."
                  }
                  violations={selectedViolations}
                  violationsTotal={violations.totalSelectedFines}
                  serviceFee={violations.serviceFeeAmount}
                  creditCardFee={violations.creditCardFeeAmount}
                  totalAdditionalTicketFee={violations.totalAdditionalTicketFee}
                  totalFees={violations.totalFees}
                  total={violations.orderTotal}
                  plateNumber={plateNumber}
                  isFinalSummary={false}
                />
              </Col>
              <Col lg={6} md={12}>
                <Form onSubmit={handleSubmit}>
                  <Container>
                    <Row className="py-3">
                      <Col>
                        <h4>Enter Your Payment Info</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <FullInput
                          title="First Name"
                          name="firstName"
                          placeholder="First Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={person.firstName.value}
                          dirty={person.firstName.dirty}
                          error={person.firstName.error}
                          type="text"
                          className="uppercase"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <FullInput
                          title="Last Name"
                          name="lastName"
                          placeholder="Last Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={person.lastName.value}
                          dirty={person.lastName.dirty}
                          error={person.lastName.error}
                          type="text"
                          className="uppercase"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FullInput
                          title="Phone Number"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={
                            person.phoneNumber.value
                              ? phoneNumbers.usFormat(person.phoneNumber.value)
                              : undefined
                          }
                          dirty={person.phoneNumber.dirty}
                          error={person.phoneNumber.error}
                          type="text"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FullInput
                          title="Email"
                          name="email"
                          placeholder="Email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={person.email.value}
                          dirty={person.email.dirty}
                          error={person.email.error}
                          type="text"
                        />
                      </Col>
                    </Row>
                    {totalSelectedFines >= 500 || numTickSel > 2 ? (
                      <Row>
                        <Col>
                          <IDSelector
                            files={payment.files}
                            setFile={payment.setFile}
                            onImageSelect={handleImageSelection}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    {process.env.REACT_APP_USE_BRAINTREE === "true" ? (
                      <Row>
                        <Col className="mx-auto">
                          <Fade in={!!payment.clientToken}>
                            {payment.clientToken ? (
                              <BraintreeDropIn
                                onInstance={setInstance}
                                onPaymentMethodRequestable={() => {
                                  payment.setPaymentReady(true)
                                }}
                                onNoPaymentMethodRequestable={() => {
                                  payment.setPaymentReady(false)
                                }}
                                shouldReset={!!general.error}
                                options={braintreeDropInOptions}
                              />
                            ) : (
                              "Preparing Secure Payment Form..."
                            )}
                          </Fade>
                        </Col>
                      </Row>
                    ) : (
                      process.env.REACT_APP_USE_EVERVAULT === 'true'
                        ? <EvervaultDropin cardRef={evervaultCardRef} />
                        : <CkoDropin />
                    )}
                    <Row className="pt-3">
                      <Col>
                        <div className="d-flex flex-row">
                          <div
                            className="d-flex justify-content-center align-items-center pr-1"
                            style={{ paddingBottom: "90px" }}
                          >
                            <CheckBox
                              checked={payment.acceptedTerms}
                              checkColor="#53c54a"
                              onClick={payment.clickAcceptedTerms}
                            />
                          </div>
                          <div className="d-inline-flex flex-wrap justify-content-start align-items-center">
                            You accept our{" "}
                            <span
                              onClick={() => {
                                general.toggleModalVisibility("showTerms")
                              }}
                              style={{
                                margin: "0 0.5rem 0 0.5rem",
                                textDecoration: "underline",
                                cursor: "pointer"
                              }}
                            >
                              Terms of Service
                            </span>{" "}
                            and{" "}
                            <span
                              onClick={() => {
                                general.toggleModalVisibility(
                                  "showPrivacyPolicy"
                                )
                              }}
                              style={{
                                margin: "0 0.5rem 0 0.5rem",
                                textDecoration: "underline",
                                cursor: "pointer"
                              }}
                            >
                              Privacy Policy.
                            </span>
                            <h1 style={{ fontSize: 12 }}>
                              You understand that Winit is a privately held
                              company which charges the fees for this service
                              that are listed on the payments page. Winit is not
                              affiliated, associated, authorized, endorsed by,
                              or in any way officially connected with any city,
                              county or state government agency.
                            </h1>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="p-3 justify-content-end">
                      {person.firstName.value ? (
                        <Button
                          color="green"
                          shadow
                          disabled={
                            !payment.enableSubmit ||
                            !payment.paymentReady ||
                            payment.submitted
                          }
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Pay {numTickSel}
                          {numTickSel > 1 ? " Tickets" : " Ticket"}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Container>
                </Form>
              </Col>
            </Row>
          </Container>
        </Row>
      </Col>
    </Fragment>
  )
}

export default observer(PaymentComponent)
